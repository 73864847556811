<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			SingleSelectCurrency(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w(v-if='Object.keys(getExportResult).length !== 0')
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
				v-row
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.newbies_avg_gm_users')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.newbies_avg_apv_bet')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.newbies_pupd_apv_bet')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.newbies_gm_stickiness')
				v-row
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.oldbies_avg_gm_users')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.oldbies_avg_apv_bet')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.oldbies_pupd_apv_bet')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.oldbies_gm_stickiness')

				span
					v-col.chartWrapNoMargin
						b {{ $t('charts.onMemberCp') }}
					v-col.chartWrap
						VueTabulator(v-model='table_summary.table_data' :options='table_summary.table_opt')
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(pieOptions.opt0).length !== 0')
						v-chart(:option='pieOptions.opt0', ref='pieOptions.opt0', autoresize)
					TripleCharts(:firstOption='chart_options.gm_users.opt_line' :secondOption='chart_options.gm_users.opt_area' :thirdOption ='chart_options.gm_users.opt_stack')
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(pieOptions.opt1).length !== 0')
						v-chart(:option='pieOptions.opt1', ref='pieOptions.opt1', autoresize)
					TripleCharts(:firstOption='chart_options.apv_bet.opt_line' :secondOption='chart_options.apv_bet.opt_area' :thirdOption ='chart_options.apv_bet.opt_stack')
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(pieOptions.opt2).length !== 0')
						v-chart(:option='pieOptions.opt2', ref='pieOptions.opt2', autoresize)
					TripleCharts(:firstOption='chart_options.pupd_apv_bet.opt_line' :secondOption='chart_options.pupd_apv_bet.opt_area' :thirdOption ='chart_options.pupd_apv_bet.opt_stack')
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(barOption1).length !== 0')
						v-chart(:option='barOption1', ref='barOption1', autoresize)
					v-col.chartWrap(cols='12', lg='8', xs='12', v-if='Object.keys(chart_options.house_net_win.opt_line).length !== 0')
						v-chart(:option='chart_options.house_net_win.opt_line', ref='chart_options.house_net_win.opt_line', autoresize)
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(pieOptions.opt3).length !== 0')
						v-chart(:option='pieOptions.opt3', ref='pieOptions.opt3', autoresize)
					TripleCharts(:firstOption='chart_options.dpst_users.opt_line' :secondOption='chart_options.dpst_users.opt_area' :thirdOption ='chart_options.dpst_users.opt_stack')
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(pieOptions.opt4).length !== 0')
						v-chart(:option='pieOptions.opt4', ref='pieOptions.opt4', autoresize)
					TripleCharts(:firstOption='chart_options.dpst_amt.opt_line' :secondOption='chart_options.dpst_amt.opt_area' :thirdOption ='chart_options.dpst_amt.opt_stack')
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(pieOptions.opt5).length !== 0')
						v-chart(:option='pieOptions.opt5', ref='pieOptions.opt5', autoresize)
					TripleCharts(:firstOption='chart_options.pupd_dpst_amt.opt_line' :secondOption='chart_options.pupd_dpst_amt.opt_area' :thirdOption ='chart_options.pupd_dpst_amt.opt_stack')
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(barOption8).length !== 0')
						v-chart(:option='barOption8', ref='barOption8', autoresize)
					v-col.chartWrap(cols='12', lg='8', xs='12', v-if='Object.keys(chart_options.revenue_amt.opt_line).length !== 0')
						v-chart(:option='chart_options.revenue_amt.opt_line', ref='chart_options.revenue_amt.opt_line', autoresize)

</template>
<style scoped>
@media (max-width: 1500px) {
	.dataSelectBox {
		left: 0%;
	}
}
</style>
<script>
import { errorLogger } from '@/util/logger';
import { getSiteReg } from '@/api/House';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { exportXlsx } from '@/util/xlsx';
import TripleCharts from '@/components/Charts/TripleCharts';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import ECharts from 'vue-echarts';
import ExportBtn from '@/components/ExportBtn';
import Kanban from '@/components/kanbans/Kanban';
import SingleSelectCurrency from '@/components/SingleSelectCurrency';
import dateType from '@/assets/constant/dateType';
import statusCode from '@/assets/constant/statusCode';
import statusMsg from '@/util/http';
import timeIntervalType from '@/assets/constant/timeIntervalType';
import chart_option_helper from '@/assets/echarts/chart_option_helper';
import { bar, line, pie, straightBar, barPercent } from '@/assets/echarts/ocmsCommon/house/reg';
import COLORS from '@/theme/color';
import { TabulatorComponent } from 'vue-tabulator';
import {
	commaFormatter,
	nFormatter,
	trans,
	transData,
	transfer,
	toThousandslsFilter,
	rateOfExchange,
	roundOff,
	roundOffFilter,
	rdPercent,
	roundDecimal,
	roundFilter,
	getNaN,
} from '@/util/format';

export default {
	components: {
		'v-chart': ECharts,
		'VueTabulator': TabulatorComponent,
		TripleCharts,
		DaliyPicker,
		ExportBtn,
		Kanban,
		SingleSelectCurrency,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			dialog: false,
			getExportResult: {},
			helperShow: false,
			currencyStatus: true,
			show: false,
			tab: null,
			loading: false,
			dates: [],
			searchDates: [],
			dateType: dateType.BRIEF_DATE,
			imgSrc: require('@/assets/image/research.png'),
			groupName: timeIntervalType['bkwd'],
			groupKey: Object.keys(timeIntervalType['bkwd']),
			groupValue: Object.values(timeIntervalType['bkwd']),
			groupColor: [
				COLORS.carrot[3],
				COLORS.carrot[4],
				COLORS.carrot[5],
				COLORS.belize_hole[3],
				COLORS.belize_hole[4],
				COLORS.belize_hole[5],
				COLORS.carrot[7],
				COLORS.belize_hole[7],
			],
			otherColor: [
				COLORS.carrot[3],
				COLORS.carrot[4],
				COLORS.carrot[5],
				COLORS.belize_hole[3],
				COLORS.belize_hole[4],
				COLORS.belize_hole[5],
			],
			allGroup: ['0~1', '2~7', '8~29', '30~89', '90~359', '360++'],
			newGroup: [ '8~29', '2~7', '0~1' ],
			oldGroup: [ '360++', '90~359', '30~89' ],
			term: 0,
			cardData: {},
			chart_options: {
				gm_users: {opt_line: {}, opt_area: {}, opt_stack: {}},
				apv_bet: {opt_line: {}, opt_area: {}, opt_stack: {}},
				pupd_apv_bet: {opt_line: {}, opt_area: {}, opt_stack: {}},
				house_net_win: {opt_line: {}},
				dpst_users: {opt_line: {}, opt_area: {}, opt_stack: {}},
				dpst_amt: {opt_line: {}, opt_area: {}, opt_stack: {}},
				pupd_dpst_amt: {opt_line: {}, opt_area: {}, opt_stack: {}},
				revenue_amt: {opt_line: {}},
			},
			barOption1: {},
			barOption8: {},
			pieOptions: {
				opt0: {},
				opt1: {},
				opt2: {},
				opt3: {},
				opt4: {},
				opt5: {},
			},
			table_summary: {},
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				const reqData = {
					currency: this.currency,
					startDate: this.$moment(this.dates[0]).format(dateType.DATE),
					endDate: this.$moment(this.dates[1]).format(dateType.DATE),
					domain: [this.value],
				};
				this.searchDates = [reqData.startDate, reqData.endDate];
				const res = await getSiteReg(reqData);
				if (res.status === statusCode.STATUS_OK) {
					localStorage.setItem('currency', this.currency);
					const result = JSON.parse(JSON.stringify(res.result.res));
					// console.log('result', result);
					// this.recapAll = result.ctx_recap_all[0];
					// this.earlyRAll  = result.ctx_recap_all_early[0];
					this.recapSum = result.ctx_recap_sum;
					this.earlyRSum = result.ctx_recap_sum_early;
					this.recapOrg = result.ctx_recap_org;
					this.termlyOrg = result.ctx_termly_org;
					// this.termlyOrg.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));

					const group_data = this.allGroup.map(e_1 => {
						return this.termlyOrg.filter(e_2 => e_1 == e_2.tag_reg_ly2);
					});

					[
						'gm_users',
						'apv_bet',
						'pupd_apv_bet',
						'house_net_win',
						'dpst_users',
						'dpst_amt',
						'pupd_dpst_amt',
						'revenue_amt',
					].forEach((e_1, ix_1) => {
						chart_option_helper.build_triple_chart(
							this.chart_options[e_1],
							group_data.map((e_2, ix_2) => {
								return {
									col_data: e_2,
									col_name: e_1,
									col_show: this.allGroup[ix_2],
									color: this.groupColor[ix_2],
								};
							})
						);
					});

					const group_idx_list = this.allGroup.map((e_1, ix_1) => {
						return {key: e_1, idx: ix_1};
					});

					function sorter(a, b){
				    let ix_a = group_idx_list.find(e_1 => a == e_1.key).idx;
				    let ix_b = group_idx_list.find(e_1 => b == e_1.key).idx;
				    return ix_a - ix_b;
					}

					const col_list = [
						{col_name: 'tag_reg_ly1', col_show: 'tag_reg_ly1', tabulator_column_opt_text: {width: 120}},
						{col_name: 'tag_reg_ly2', col_show: 'tag_reg_ly2', tabulator_column_opt_text: {width: 120, sorter: sorter}},
						{col_name: 'avg_gm_users', col_show: 'gm_users', tabulator_column_opt_bar: {formatterParams: {color: [COLORS.wisteria[8]]}}},
						{col_name: 'avg_apv_bet', col_show: 'apv_bet', tabulator_column_opt_bar: {formatterParams: {color: [COLORS.wisteria[6]]}}},
						{col_name: 'pupd_apv_bet', col_show: 'apv_bet', tabulator_column_opt_bar: {formatterParams: {color: [COLORS.wisteria[4]]}}},
						{col_name: 'avg_house_net_win', col_show: 'house_net_win', tabulator_column_opt_bar: {formatterParams: {color: [COLORS.orange[5]]}}},
						{col_name: 'avg_dpst_users', col_show: 'dpst_users', tabulator_column_opt_bar: {formatterParams: {color: [COLORS.turquoise[8]]}}},
						{col_name: 'avg_dpst_amt', col_show: 'dpst_amt', tabulator_column_opt_bar: {formatterParams: {color: [COLORS.turquoise[6]]}}},
						{col_name: 'pupd_dpst_amt', col_show: 'dpst_amt', tabulator_column_opt_bar: {formatterParams: {color: [COLORS.turquoise[4]]}}},
						{col_name: 'avg_revenue_amt', col_show: 'revenue_amt', tabulator_column_opt_bar: {formatterParams: {color: [COLORS.sunflower[8]]}}},
					];

					chart_option_helper.build_table_chart(
						this.table_summary,
						this.recapOrg,
						col_list,
						'tag_reg_ly2',
						{initialSort: [{column: 'tag_reg_ly2', dir: 'asc'}]},
					);

					this.regPieChart(this.recapOrg, this.recapSum);
					this.regBarChart(this.recapOrg);
					this.cardDisplay(this.earlyRSum, this.recapSum);
					this.getExportResult = JSON.parse(JSON.stringify(result));
				}
			} catch (e) {
				errorLogger(e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async cardDisplay(res, res2) {
			let newEarly = {}, newbies = {}, oldEarly = {}, oldbies = {};
			for (let i of res) {
				if(i.tag_reg_ly1 === 'newbies') { newEarly = i; }
				if(i.tag_reg_ly1 === 'oldbies') { oldEarly = i; }
			}
			for (let i of res2) {
				if(i.tag_reg_ly1 === 'newbies') { newbies = i; }
				if(i.tag_reg_ly1 === 'oldbies') { oldbies = i; }
			}

			[
				{col_name: 'avg_gm_users', card_opt: {note: 'people'}},
				{col_name: 'avg_apv_bet', card_opt: {note: 'value'}},
				{col_name: 'pupd_apv_bet', card_opt: {note: 'value'}},
				{col_name: 'gm_stickiness', card_opt: {note: 'percent'}},
			].forEach(e_1 => {
				this.cardData['newbies_' + e_1.col_name] = chart_option_helper.build_card(e_1.col_name, newbies, newEarly, {title_prefix: 'newbies'}, e_1.card_opt);
			});

			[
				{col_name: 'avg_gm_users', card_opt: {note: 'people'}},
				{col_name: 'avg_apv_bet', card_opt: {note: 'value'}},
				{col_name: 'pupd_apv_bet', card_opt: {note: 'value'}},
				{col_name: 'gm_stickiness', card_opt: {note: 'percent'}},
			].forEach(e_1 => {
				this.cardData['oldbies_' + e_1.col_name] = chart_option_helper.build_card(e_1.col_name, oldbies, oldEarly, {title_prefix: 'oldbies'}, e_1.card_opt);
			});
		},
		async regPieChart(res, res2) {
			if(undefined == res)
				return;

			const legendData = this.groupValue;
			const all_group = this.allGroup;
			function fetch(col_name){
				let ret = [];
				all_group.forEach((elem_1) => {
					res.forEach((elem_2) => {
						if(elem_1 == elem_2.tag_reg_ly2)
							ret.push({name: elem_1, value: elem_2[col_name] || 0});
						else
							ret.push({name: elem_1, value: 0});
					});
				});
				return ret;
			}

			let ctx_newbies, ctx_oldbies;
			for (let elem of res2) {
				if(elem.tag_reg_ly1 === 'newbies')
					ctx_newbies = elem;
				if(elem.tag_reg_ly1 === 'oldbies')
					ctx_oldbies = elem;
			}

			[
				{col_name: 'avg_gm_users', col_title: this.$t('modifier.prefix_avg') + this.$t('metric.gm_users')},
				{col_name: 'avg_apv_bet', col_title: this.$t('modifier.prefix_avg') + this.$t('metric.apv_bet')},
				{col_name: 'pupd_apv_bet', col_title: this.$t('modifier.prefix_pupd') + this.$t('metric.apv_bet')},
				{col_name: 'avg_dpst_users', col_title: this.$t('modifier.prefix_avg') + this.$t('metric.dpst_users')},
				{col_name: 'avg_dpst_amt', col_title: this.$t('modifier.prefix_avg') + this.$t('metric.dpst_amt')},
				{col_name: 'pupd_dpst_amt', col_title: this.$t('modifier.prefix_pupd') + this.$t('metric.dpst_amt')},
			].forEach((e_1, ix_1) => {
				const opt_key = Object.keys(this.pieOptions)[ix_1];

				this.pieOptions[opt_key] = pie({
					name: '',
					legend: legendData,
					color: this.groupColor,
					title: this.$t('headline.newbies_n_oldbies') + e_1.col_title,
					series: fetch(e_1.col_name),
				});
				this.pieOptions[opt_key].tooltip.formatter = (params) => {
					return `${params.marker}${params.name}: ${roundOffFilter(params.value)} (${params.percent}%)`;
				};
				this.pieOptions[opt_key].series.push({
					name: '',
					type: 'pie',
					radius: [0, '30%'],
					label: {position: 'inner'},
					labelLine: {show: false},
					data: [
						{name: this.$t('customer.newMember'), value: ctx_newbies[e_1.col_name]},
						{name: this.$t('customer.oldMember'), value: ctx_oldbies[e_1.col_name]},
					],
				});
			});
		},
		async regBarChart(res) {
			if (res) {
				const key = ['360++', '90~359', '30~89', '8~29', '2~7', '0~1'];
				// const bkwd = JSON.parse(JSON.stringify(timeIntervalType['bkwd']));
				const xAxisData = JSON.parse(JSON.stringify(Object.values(timeIntervalType['bkwd'])));
				// const yAxisData = this.groupValue;
				const totalNetWin = {};
				const netArr = [], totalNW = [];
				let revenueArr = [];
				for (let j of key) {
					for (let i of res) {
						if (j === i.tag_reg_ly2) {
							netArr.push(i.avg_house_net_win);
							totalNW.push(i.ttl_house_net_win);
							revenueArr.push(i.avg_revenue_amt);
						}
					}
				}
				netArr.reverse();
				totalNW.reverse();
				revenueArr.reverse();
				this.barOption1 = bar({ // net win waterfall
					color: this.otherColor,
					title: this.$t('headline.newbies_n_oldbies') + this.$t('modifier.prefix_avg') + this.$t('metric.house_net_win'),
					name: '',
					// yAxis: xAxisData,
					yAxis: [''],
					series: [],
				});
				for(let i = 0; i < xAxisData.length; i += 1) {
					this.barOption1.series.push({
						name: xAxisData[i],
						type: 'bar',
						stack: xAxisData[i],
						label: {
							show: true,
							position: 'inside',
							color: '#FFF',
							formatter: (params) => {
								return xAxisData[params.componentIndex];
							}
						},
						data: [netArr[i]]
					});
					totalNetWin[xAxisData[i]] = totalNW[i];
				}
				this.barOption1.title.textStyle.fontSize = 20;
				this.barOption1.grid.right = '18%';
				this.barOption1.yAxis[0].position = 'right';
				this.barOption1.tooltip.formatter = (params) => {
					let str = ``;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
						// str += `${i.marker}${i.seriesName}:<br/>
						// AVG. Daily: ${roundOffFilter(i.value)}<br/>
						// Total: ${roundOffFilter(totalNetWin[i.seriesName])}<br/>`
					}
					return str;
				};
				this.barOption8 = bar({
					color: this.otherColor,
					title: this.$t('headline.newbies_n_oldbies') + this.$t('modifier.prefix_avg') + this.$t('metric.revenue_amt'),
					name: '',
					yAxis: [''],
					series: [],
				});
				for(let i = 0; i < xAxisData.length; i += 1) {
					this.barOption8.series.push({
						name: xAxisData[i],
						type: 'bar',
						stack: xAxisData[i],
						label: {
							show: true,
							position: 'inside',
							color: '#FFF',
							formatter: (params) => {
								return xAxisData[params.componentIndex];
							}
						},
						data: [revenueArr[i]]
					});
				}
				this.barOption8.title.textStyle.fontSize = 20;
				this.barOption8.grid.right = '18%';
				this.barOption8.yAxis[0].position = 'right';
				this.barOption8.tooltip.formatter = (params) => {
					let str = ``;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		allClean() {
			this.cardData = {};
			for(let key_1 in this.chart_options){
				for(let key_2 in this.chart_options[key_1]){
					this.chart_options[key_1][key_2] = {};
				}
			}
			this.barOption1 = {};
			this.barOption8 = {};
			for(let key in this.pieOptions)
				this.pieOptions[key] = {};
			this.table_summary = {};
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.domainMap = val2;
			this.currency = val3;
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		closeDialog() {
			this.dialog = false;
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] === 'daily') {
					this.tab = 0;
				}
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		updateDate(val) {
			this.dates = val;
		},
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top || { top: NaN }.top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
	},
	computed: {
		...mapGetters(['getDomain', 'getDialogShow', 'getStatusDialog', 'getOperatorDefaultRouter', 'getOpenAutoSearch']),
	},
	created() {},
};
</script>
