import _ from 'lodash';
import moment from 'moment';
import repla, { replaObjInArray, replaObj } from '@/util/dataFilter';
import fetch from '@/util/fetch';
// import i18n from '@/locale';
import statusCode from '@/assets/constant/statusCode';
// import timeIntervalType from '@/assets/constant/timeIntervalType';
// import transType from '@/assets/constant/transType';
import { pRank, rdPercent, roundDecimal, roundOff} from '@/util/format';

export async function getSiteCvt(reqData) {
	const result = await fetch({
		url: '/cars/ocms/house/cvt',
		method: 'post',
		data: {
			reqData,
		},
	});
	const day = moment(reqData.endDate).diff(reqData.startDate, 'days') + 1;
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		// await repla(tmp);
		await replaObjInArray(tmp.ctx_kpi_ret_early[0].ctx_act_agg);
		await replaObjInArray(tmp.ctx_kpi_ret[0].ctx_act_agg);
		tmp.ctx_act_org_early = [];
		tmp.ctx_act_sum_early = [];
		tmp.ctx_act_all_early = [];
		tmp.ctx_cvt_org_early = [];
		tmp.ctx_cvt_all_early = [];
		tmp.ctx_cvt_org = [];
		tmp.ctx_cvt_all = [];
		tmp.ctx_act_org = [];
		tmp.ctx_act_sum = [];
		tmp.ctx_act_all = [];
		for (const i of tmp.ctx_kpi_ret_early[0].ctx_act_agg) {
			if(i.dim_ptt) {
				i.dim_ptt = (i.dim_ptt) ? moment.utc(i.dim_ptt.value).format('YYYY-MM-DD') : null;
				tmp.ctx_act_org_early.push(i);
			} else {
				if(!_.isNull(i.tag_reg_term) && i.tag_reg_term !== 0) {
					tmp.ctx_act_sum_early.push(i);
				} else {
					tmp.ctx_act_all_early.push(i);
				}
			}
		}
		for (const i of tmp.ctx_kpi_ret[0].ctx_act_agg) {
			if(i.dim_ptt) {
				i.dim_ptt = (i.dim_ptt) ? moment.utc(i.dim_ptt.value).format('YYYY-MM-DD') : null;
				tmp.ctx_act_org.push(i);
			} else {
				if(!_.isNull(i.tag_reg_term) && i.tag_reg_term !== 0) {
					tmp.ctx_act_sum.push(i);
				} else {
					tmp.ctx_act_all.push(i);
				}
			}
		}
		for (const i of tmp.ctx_kpi_ret[0].ctx_act_each) {
			i.dim_ptt = (i.dim_ptt) ? moment.utc(i.dim_ptt.value).format('YYYY-MM-DD') : null;
			i.retention = !_.isNull(i.retention)? i.retention: 0;
			i.ttl_gm_users_esti = !_.isNull(i.ttl_gm_users_esti)? parseFloat(i.ttl_gm_users_esti): 0;
			i.ttl_gm_users_real = !_.isNull(i.ttl_gm_users_real)? parseFloat(i.ttl_gm_users_real): 0;
		}
		for (const i of tmp.ctx_site_cvt_early) {
			if(i.dim_ptt) {
				i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
				tmp.ctx_cvt_org_early.push(i);
			} else {
				i.dim_ptt = null;
				tmp.ctx_cvt_all_early.push(i);
			}
		}
		for (const i of tmp.ctx_site_cvt) {
			if(i.dim_ptt) {
				i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
				tmp.ctx_cvt_org.push(i);
			} else {
				i.dim_ptt = null;
				tmp.ctx_cvt_all.push(i);
			}
		}
	}
	return result;
}

export async function getSiteReg(reqData) {
	const result = await fetch({
		url: '/cars/ocms/house/reg',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		await replaObjInArray(tmp.ctx_reg[0].ctx_recap);
		await replaObjInArray(tmp.ctx_reg[0].ctx_termly);
		tmp.ctx_recap_org_early = [];
		tmp.ctx_recap_sum_early = [];
		tmp.ctx_recap_all_early = [];
		tmp.ctx_recap_org = [];
		tmp.ctx_recap_sum = [];
		tmp.ctx_recap_all = [];
		tmp.ctx_termly_org = [];
		tmp.ctx_termly_sum = [];
		for(const i of tmp.ctx_reg_early) {
			i.avg_apv_bet = parseFloat(i.avg_apv_bet);
			i.avg_dpst_amt = parseFloat(i.avg_dpst_amt);
			i.avg_house_net_win = parseFloat(i.avg_house_net_win);
			i.pupd_apv_bet = parseFloat(i.pupd_apv_bet);
			i.ttl_apv_bet = parseFloat(i.ttl_apv_bet);
			i.ttl_dpst_amt = parseFloat(i.ttl_dpst_amt);
			i.ttl_house_net_win = parseFloat(i.ttl_house_net_win);
			if(i.tag_reg_ly1) {
				if(i.tag_reg_ly2) {
					tmp.ctx_recap_org_early.push(i);
				} else {
					tmp.ctx_recap_sum_early.push(i);
				}
			} else {
				tmp.ctx_recap_all_early.push(i);
			}
		}
		for(const i of tmp.ctx_reg[0].ctx_recap) {
			i.avg_apv_bet = parseFloat(i.avg_apv_bet);
			i.avg_dpst_amt = parseFloat(i.avg_dpst_amt);
			i.avg_house_net_win = parseFloat(i.avg_house_net_win);
			i.avg_revenue_amt = parseFloat(i.avg_revenue_amt);
			i.pupd_apv_bet = parseFloat(i.pupd_apv_bet);
			i.ttl_apv_bet = parseFloat(i.ttl_apv_bet);
			i.ttl_dpst_amt = parseFloat(i.ttl_dpst_amt);
			i.ttl_house_net_win = parseFloat(i.ttl_house_net_win);
			i.ttl_revenue_amt = parseFloat(i.ttl_revenue_amt);
			if(i.tag_reg_ly1) {
				if(i.tag_reg_ly2) {
					tmp.ctx_recap_org.push(i);
				} else {
					tmp.ctx_recap_sum.push(i);
				}
			} else {
				tmp.ctx_recap_all.push(i);
			}
		}
		for(const i of tmp.ctx_reg[0].ctx_termly) {
			i.apv_bet = parseFloat(i.apv_bet);
			i.dpst_amt = parseFloat(i.dpst_amt);
			i.house_net_win = parseFloat(i.house_net_win);
			i.pupd_apv_bet = parseFloat(i.pupd_apv_bet);
			i.revenue_amt = parseFloat(i.revenue_amt);
			if(i.dim_ptt) {
				i.dim_ptt = (i.dim_ptt) ? moment.utc(i.dim_ptt.value).format('YYYY-MM-DD') : null;
				tmp.ctx_termly_org.push(i);
			} else {
				tmp.ctx_termly_sum.push(i);
			}
		}
	}
	return result;
}

export async function getSiteTop(reqData) {
	const result = await fetch({
		url: '/cars/ocms/house/top',
		method: 'post',
		data: {
			reqData,
		},
	});
	// const day = moment(reqData.endDate).diff(reqData.startDate, 'days') + 1;
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		tmp.ctx_recap_org = [];
		tmp.ctx_recap_sum = [];
		tmp.ctx_recap_all = [];
		tmp.ctx_recap_org_early = [];
		tmp.ctx_recap_sum_early = [];
		tmp.ctx_recap_all_early = [];
		tmp.ctx_termly_org = [];
		tmp.ctx_termly_sum = [];
		for(const i of tmp.ctx_top_detail) {
			i.bet_amt = parseFloat(i.bet_amt);
			// i.bet_pr = parseFloat(i.bet_pr);
			i.bet_run_ratio = roundDecimal(parseFloat(i.bet_run_ratio) * 100);
		}
		for(const i of tmp.ctx_top_recap) {
			i.avg_apv_bet = parseFloat(i.avg_apv_bet);
			i.avg_house_net_win = parseFloat(i.avg_house_net_win);
			i.pupd_apv_bet = parseFloat(i.pupd_apv_bet);
			i.ttl_apv_bet = parseFloat(i.ttl_apv_bet);
			i.ttl_house_net_win = parseFloat(i.ttl_house_net_win);
			if(i.tag_top_ly1) {
				if(i.tag_top_ly2 !== null) {
					i.tag_top_ly2 = i.tag_top_ly2.toString();
					tmp.ctx_recap_org.push(i);
				} else {
					tmp.ctx_recap_sum.push(i);
				}
			} else {
				tmp.ctx_recap_all.push(i);
			}
		}
		for(const i of tmp.ctx_top_recap_early) {
			i.avg_apv_bet = parseFloat(i.avg_apv_bet);
			i.avg_house_net_win = parseFloat(i.avg_house_net_win);
			i.pupd_apv_bet = parseFloat(i.pupd_apv_bet);
			i.ttl_apv_bet = parseFloat(i.ttl_apv_bet);
			i.ttl_house_net_win = parseFloat(i.ttl_house_net_win);
			if(i.tag_top_ly1) {
				if(i.tag_top_ly2 !== null) {
					i.tag_top_ly2 = i.tag_top_ly2.toString();
					tmp.ctx_recap_org_early.push(i);
				} else {
					tmp.ctx_recap_sum_early.push(i);
				}
			} else {
				tmp.ctx_recap_all_early.push(i);
			}
		}
		for(const i of tmp.ctx_top_termly) {
			i.apv_bet = parseFloat(i.apv_bet);
			i.house_net_win = parseFloat(i.house_net_win);
			i.pupd_apv_bet = parseFloat(i.pupd_apv_bet);
			if(i.dim_ptt) {
				i.dim_ptt = (i.dim_ptt) ? moment.utc(i.dim_ptt.value).format('YYYY-MM-DD') : null;
				i.tag_top_ly2 = i.tag_top_ly2.toString();
				tmp.ctx_termly_org.push(i);
			} else {
				tmp.ctx_termly_sum.push(i);
			}
		}
	}
	return result;
}
